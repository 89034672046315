<template>
  <div>
    <h2>{{ $t('views.users.services.title') }}</h2>

    <b-table
      :busy="is.loading || relationsIs.loading"
      :fields="tableData.fields"
      :items="tableData.items"
      borderless
      striped
      hover
      show-empty
      sort-icon-left
      thead-class="d-none"
      :tbody-tr-class="table.helper.rowClass"
    >
      <template #table-busy>
        <div class="text-center">
          <loader inline/>
          <div>{{ $t('components.table.busy') }}</div>
        </div>
      </template>

      <template #cell(subscribed)="data">
        <control-checkbox :id="`${data.item.id}-toggle`" class="mb-0" v-model="data.value" switch @change="$value => updateRelation(data.field.key, data.item, $value)"/>
      </template>

      <template #row-details="data">
        <template v-if="data.item.isTitle">
          <h3 class="h5">{{ data.item.title }}</h3>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import Table, { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import Loader from '@/components/Loader'
import ControlCheckbox from '@/components/form/ControlCheckbox'

const ENTITY_KEY = 'services'
const RELATION_KEY = 'userservices'

export default {
  name: 'Users.Services',
  mixins: [MixinEntityBase],
  components: {
    Loader,
    ControlCheckbox
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      table: {
        helper: TableHelper,
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: ['subscribed', 'name', 'description'],
            sortable: ['name', 'description'],
            labelKey: 'views.users.services.table.label.{field}',
            label: {},
            variant: {},
            class: {},
            thClass: {},
            tdClass: {}
          }
        }
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eCurrent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.eCurrent.key}/getUnwrapped`]
    },
    relationsIs () {
      return this.$store.getters[`${this.relationKey}/is`]
    },
    relationsDefinition () {
      return this.$store.getters[`${this.relationKey}/definition`]
    },
    relations () {
      return this.$store.getters[`${this.relationKey}/getUnwrapped`]
    },
    tableEntities () {
      let previousCategoryKey = null

      return []
        .concat(this.entities)
        .sort(TableHelper.sortByProperties('category', `name.${this.$store.getters['gui/getLanguage']}`))
        .map(e => {
          const ENTITY = e
          const RELATION = this.relations.find(r => r.subscriptionAndServiceId === ENTITY.id) || {}

          const CATEGORY_KEY = ENTITY.category || null
          const CATEGORY_TRANSLATIONS = ((((this.definition.$properties || {}).category || {}).translations || {})[this.$i18n.locale] || {}).selectValues || {}

          const _table = CATEGORY_KEY && CATEGORY_KEY !== previousCategoryKey ? { title: CATEGORY_TRANSLATIONS[CATEGORY_KEY] } : null

          previousCategoryKey = CATEGORY_KEY

          return {
            _table,
            _entity: ENTITY,
            _relation: RELATION,
            id: ENTITY.id,
            subscribed: RELATION.subscribed || false,
            name: getTranslatedValue.bind(this)(ENTITY.name),
            description: getTranslatedValue.bind(this)(ENTITY.description),
            hasRelation: RELATION.subscribed !== undefined,
            status: ENTITY.status
          }
        })

      function getTranslatedValue (property = {}) {
        return property[this.$i18n.locale] || property[this.$i18n.fallbackLocale]
      }
    },
    tableData () {
      return new Table(this.tableEntities, this.table.options.fields)
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: this.eParent })
      this.$store.dispatch(`${this.eCurrent.key}/getAll`)
    },
    updateRelation (key, item, value) {
      const action = item.hasRelation ? 'updateEntity' : 'createEntity'
      const id = item.hasRelation ? item._relation.id : undefined

      this.$store.dispatch(`${this.relationKey}/${action}`, {
        id,
        userId: this.eParent.id,
        subscriptionAndServiceId: item.id,
        subscribed: value
      })
    }
  },
  created () {
    this.getEntities()
  }
}
</script>

<style lang="scss"></style>
