import merge from 'lodash.merge'

export function flatten (object = {}, defaultValue) {
  return f(object)

  function f (o, parentKey = '') {
    let fObj = {}

    Object.keys(o).forEach(k => {
      const isArray = Array.isArray(o[k])
      const isObject = !isArray && typeof o[k] === 'object'
      const fKey = k === defaultValue ? parentKey : `${parentKey}${parentKey ? '.' : ''}${k}`

      if (isArray) {
        o[k].forEach((p, i) => {
          fObj = Object.assign(fObj, f({ [i]: o[k][i] }, fKey))
        })
      } else if (isObject) {
        fObj = Object.assign(fObj, f(o[k], fKey))
      } else {
        fObj[fKey] = o[k]
      }
    })

    return fObj
  }
}

export function unflatten (object = {}, splitter = '.') {
  const uObj = {}

  for (const flatKey in object) {
    assign(uObj, flatKey.split(splitter), object[flatKey])
  }

  return uObj

  function assign (bObj, keys, value) {
    const lastKeyIndex = keys.length - 1

    for (var i = 0; i < lastKeyIndex; ++i) {
      if (!(keys[i] in bObj)) bObj[keys[i]] = {}
      bObj = bObj[keys[i]]
    }

    bObj[keys[lastKeyIndex]] = value
  }
}

export function deepKeys (object = {}, exclude) {
  let dKeys = []

  for (const key in object) {
    if (key !== exclude) {
      dKeys.push(key)
      if (typeof object[key] === 'object') dKeys = dKeys.concat(deepKeys(object[key], exclude).map(sKey => `${key}.${sKey}`))
    }
  }

  return dKeys
}

export function propertyByStringPath (object = {}, stringPath = '', returnUndefined = false) {
  const propertyIfUndefined = !returnUndefined ? stringPath : undefined
  let property = Object.assign({}, object)

  stringPath
    .split('.')
    .forEach(pKey => {
      property = property[pKey] || {}
    })

  return typeof property === 'string' ? property || propertyIfUndefined : propertyIfUndefined
}

export function copy (data) {
  const mergeBase = Array.isArray(data) ? [] : {}
  return merge(mergeBase, data)
}

export default {
  flatten,
  unflatten,
  deepKeys,
  propertyByStringPath,
  copy
}
