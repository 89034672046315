import Vue from 'vue'
import i18n from '@/$plugins/i18n/core'
import merge from 'lodash.merge'
import { CLASS_TABLE_GLOBAL_FIELD_EXCLUDES, CLASS_TABLE_FIELD_KEY_ALL, CLASS_TABLE_FIELD_KEY_UNKNOWN, CLASS_TABLE_FIELD_KEYS_UNSORTABLE } from '@/constants'
import { propertyByStringPath } from '@/assets/js/helper/object'

export const OPTIONS_BASE = {
  includes: [],
  excludes: [],
  sorting: [],
  sortable: [],
  labelKey: '', // e.g. 'components.table.fields.{field}'
  label: {},
  variant: {},
  thClass: {},
  tdClass: {},
  class: {}
}

const tBodyTrClassHooks = []
let detailshowing = []

export const TableHelper = {
  rowClass: tBodyTrClass,
  rowClassHook: registerTBodyTrClassHook,
  toggleDetail: trClickToggleDetail,
  rememberOpenDetail: rememberDetailhowing,
  restoreOpenDetail: restoreDetailShowing,
  sortByProperties
}

export default class Table {
  constructor (items = [], options = {}) {
    const o = merge({}, OPTIONS_BASE, options)

    this.fields = []
    this.items = items

    this.fieldsCreate(items, o)
    this.itemsCreateTitles(items, o)
  }

  fieldsCreate (items = [], options = {}) {
    if (items.length > 0) {
      const o = merge({}, OPTIONS_BASE, options)

      this.fields = arrayIncludeKeys(arrayDistinct(arrayExcludeKeys(items.map(item => Object.keys(item)).flat(), o.excludes)), o.includes)
      this.fieldsRemoveUnkown(this.fields, o)
      this.fieldsSort(this.fields, o)
      this.fieldsMap(this.fields, o)

      if (this.fields.length === 0) {
        console.warn('Table Warning: No table-field has left to render! Instead table shows all data-properties.')
      }
    }
  }

  fieldsRemoveUnkown (fields = [], options = {}) {
    const o = merge({}, OPTIONS_BASE, options)
    const knownFields = [].concat(o.includes, o.sorting, o.sortable)
    const removeUnknown = o.sorting.indexOf(CLASS_TABLE_FIELD_KEY_UNKNOWN) < 0

    if (removeUnknown) {
      this.fields = fields
        .filter(field => {
          const fieldKey = typeof field === 'object' ? field.key : field
          return knownFields.includes(fieldKey)
        })
    }
  }

  fieldsMap (fields = [], options = {}) {
    const o = merge({}, OPTIONS_BASE, options)

    this.fields = fields
      .map(field => {
        const fieldKey = typeof field === 'object' ? field.key : field
        const labelKey = o.labelKey.replace(/{field}/g, fieldKey)

        return {
          key: fieldKey,
          sortable: !CLASS_TABLE_FIELD_KEYS_UNSORTABLE.includes(fieldKey) ? o.sortable.includes(CLASS_TABLE_FIELD_KEY_ALL) || o.sortable.includes(fieldKey) : false,
          label: o.label[fieldKey] !== undefined ? o.label[fieldKey].toString() : i18n.te(labelKey) ? i18n.t(labelKey) : fieldKey,
          variant: o.variant[Object.keys(o.variant).find(vKey => vKey === fieldKey)] || null,
          thClass: [`cell-id-${stringToKebabCase(fieldKey)} col-id-${stringToKebabCase(fieldKey)}`].concat(o.thClass[fieldKey] || []),
          tdClass: [`col-id-${stringToKebabCase(fieldKey)}`].concat(o.tdClass[fieldKey] || []),
          class: [].concat(o.class[fieldKey] || [])
        }
      })
  }

  fieldsSort (fields = [], options = {}) {
    const o = merge({}, OPTIONS_BASE, options)

    let unknownIndex = o.sorting.indexOf(CLASS_TABLE_FIELD_KEY_UNKNOWN)
    unknownIndex = unknownIndex >= 0 ? unknownIndex : this.fields.length

    this.fields = fields.sort((a, b) => {
      const aIndex = o.sorting.indexOf(typeof a === 'object' ? a.key : a)
      const bIndex = o.sorting.indexOf(typeof b === 'object' ? b.key : b)

      return (aIndex >= 0 ? aIndex : unknownIndex) - (bIndex >= 0 ? bIndex : unknownIndex)
    })
  }

  itemsCreateTitles (items = []) {
    this.items = items.reduce((rows, item) => {
      const _table = item._table || {}

      return rows.concat(
        _table.title ? Object.assign({ title: _table.title, isTitle: true, _hasDetails: true, _showDetails: true }, item) : [],
        item
      )
    }, [])
  }
}

function arrayIncludeKeys (fields = [], includes = []) {
  return [].concat(fields, includes)
}

function arrayExcludeKeys (fields = [], excludes = []) {
  const fieldsExcludes = [].concat(CLASS_TABLE_GLOBAL_FIELD_EXCLUDES, excludes)
  return fields.filter(field => !fieldsExcludes.includes(field))
}

function arrayDistinct (array = []) {
  return Array.from(new Set(array))
}

function stringToKebabCase (string = '') {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

function tBodyTrClass (item = {}, rowType = '') {
  let classes = []

  if (item) {
    if (rowType === 'row' && item._hasDetails) classes.push('has-details')
    if (rowType === 'row-details') classes.push('is-detail')

    if (item.isTitle) {
      if (rowType === 'row') classes.push('d-none')
      if (rowType === 'row-details') classes.push('is-title')
    }

    tBodyTrClassHooks.forEach(h => {
      classes = classes.concat(h(item, rowType) || [])
    })
  }

  return classes.join(' ')
}

function registerTBodyTrClassHook (hook = null) {
  if (typeof hook === 'function') tBodyTrClassHooks.push(hook)
}

function trClickToggleDetail (item = {}, index = null, event = {}) {
  if (item) {
    if (item._hasDetails) {
      Vue.set(item, '_showDetails', !item._showDetails)
      if (typeof item._showDetailsHook === 'function') item._showDetailsHook(item._showDetails, item)
    }
  }
}

function rememberDetailhowing (rows = [], property = null) {
  detailshowing = rows.reduce((elements, r, i) => elements.concat(r._showDetails ? { id: r[property] || null, index: i } : []), [])
}

function restoreDetailShowing (rows = [], property = null) {
  detailshowing.forEach(element => {
    Vue.set(rows.find(r => r[property] === element.id) || rows[element.index], '_showDetails', true)
  })
}

// sort items by simple Properties (e.g. strings, numbers, booleans)
function sortByProperties (...propertyPaths) {
  return function (propertyA, propertyB) {
    return propertyPaths
      .map(propertyPath => ({ a: getProperty(propertyA, propertyPath), b: getProperty(propertyB, propertyPath) }))
      .reduce((compare, property) => getCompareValue(compare, property.a, property.b), 0)
  }

  function getProperty (object = {}, propertyPath = '') {
    const propertyValue = propertyByStringPath(object, propertyPath, true)
    return typeof propertyValue === 'string' ? propertyValue.toLowerCase() : propertyValue
  }

  function getCompareValue (compare, propertyA, propertyB) {
    if (compare === 0) {
      if (propertyA !== undefined && propertyB === undefined) compare = -1
      else if (propertyA === undefined && propertyB !== undefined) compare = 1
      else if (propertyA === true && propertyB !== true) compare = -1
      else if (propertyA !== true && propertyB === true) compare = 1
      else if (propertyA < propertyB) compare = -1
      else if (propertyA > propertyB) compare = 1
    }

    return compare
  }
}
